<template>
    <b-container fluid="md">
        <b-alert show variant="info" class="mt-5 fs-5">
            ZohoCheker - сервіс перевірки даних, що надходить із Zoho до рекламних лендів та при оплаті.<br>
            Для початку роботи просто введіть у поле id або посилання на захід із zoho, наприклад:
            <b-alert show variant="dark" class="mt-3 fs-6">
                https://crm.zoho.com/crm/login.sas?serviceurl=%2Fcrm%2Forg634362728%2Ftab%2FProducts%2F2108311000496235869
            </b-alert>
        </b-alert>

        <b-form class="mt-5" @submit.prevent="onSubmit" @reset="onReset" v-if="show">
            <b-form-input class="fs-5" v-model="zohoInput" placeholder="crm.zoho.com/Products/..."></b-form-input>
            <b-alert show variant="danger" v-show="errorMsg">Невірно введенно дані</b-alert>
            <b-button-group class="mt-3">
                <b-button @click="onSubmit" variant="outline-primary" class="fs-4">Перевірити</b-button>
                <b-button @click="onReset" variant="outline-danger" class="fs-4">Очистити поле</b-button>
            </b-button-group>
        </b-form>

        <div class="text-center">
            <b-spinner style="width: 4rem; height: 4rem;" class="mt-5 mb-5" v-if="loadingApi" variant="primary"></b-spinner>
        </div>
        
        <div v-if="showTable" class="mt-5 mb-5 p-3">
            <b-row class="border border-primary border-2 rounded-3">
                <b-col class="p-4">
                    <strong class="fs-4">Ціна із знижкою:</strong>
                    <p class="fs-4">{{price}}</p>
                </b-col>
                <b-col class="p-4">
                    <strong class="fs-4">Максимальна ціна:</strong>
                    <p class="fs-4">{{maxPrice}}</p>
                </b-col>
                <b-col class="p-4">                
                    <strong class="fs-4">Дата зниження ціни:</strong>
                    <p class="fs-4">{{beforeDate}}</p>
                </b-col>
                <b-col class="p-4">        
                    <strong class="fs-4">Валюта:</strong>
                    <p class="fs-4">{{currency}}</p>
                </b-col>
            </b-row>
        </div>
    </b-container>
</template>

<script>
    import axios from "axios";

    export default {
        name: 'formCheck',

        data() {
            return {
                show: true,
                zohoInput: '',
                landingId: '',
                showTable: false,
                errorMsg: false,
                token: 'LapQMWHF9k5QPPGRkfRnAtACAGwUcX2tkaVgyDuQe76crMGnrU',
                loadingApi: false
            }
        },

        watch:{
            $route (){
                this.watchRouter()
            }
        },

        created: function () {
           this.watchRouter()
        },

        props: {},

        methods: {
            onSubmit: function () {
                this.setValidId(this.zohoInput);
                if(!this.zohoInput.length < 1) {
                    this.fetchApi()
                } else {
                    this.errorMsg = true;
                }
            },
            setValidId: function (string) {
                this.pairs = string.trim().toLowerCase();
                this.pair = this.pairs.split('%');

                this.lastItem = this.pair[this.pair.length - 1];

                if ((this.lastItem).indexOf('f') >= 0) {
                    this.landingId = this.lastItem.slice(2);
                } else {
                    this.landingId = this.lastItem
                }
            },
            fetchApi: async function () {
                this.showTable = false;
                this.loadingApi = true;
                this.errorMsg = false;

                this.url =
                    `https://crm-oz.constructor.biz.ua/landing/price?landing_id=${this.landingId}&token=${this.token}`;

                axios
                    .get(this.url)
                    .then(response => {
                        this.createTable(response.data);
                        this.$router.push({ query: { id: this.landingId }}).catch(()=>{});
                    })
                    .catch(error => {
                        console.log(error);
                        this.errorMsg = true;
                        this.loadingApi = false;
                        this.showTable = false;
                    });
            },
            watchRouter: function () {
                if(this.$route.query.id !== undefined) {
                    this.zohoInput = this.$route.query.id
                    this.landingId = this.$route.query.id

                    this.fetchApi()
                }
            },
            createTable: function (data) {
                this.price = data.price || 'Дані відсутні';
                this.maxPrice = data.maxPrice || 'Дані відсутні';
                this.beforeDate = data.beforeDate || 'Дані відсутні';
                this.currency = data.currency || 'Дані відсутні';

                this.loadingApi = false;
                this.showTable = true;
            },
            onReset: function () {
                this.errorMsg = false;
                this.zohoInput = '';
                this.showTable = false;
                this.$router.replace({'id': null}).catch(()=>{});
            }
        }
    }
</script>

<style>
</style>