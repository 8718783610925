<template>
<div>
  <b-navbar toggleable="lg" type="dark" variant="info">
    <b-container fluid="md">
      <b-navbar-brand href="#" class="fs-2">ZohoCheck</b-navbar-brand>
    </b-container>
  </b-navbar>
</div>
</template>

<script>
    export default {
        name: 'headerMain',
        props: {}
    }
</script>


<style scoped>

</style>