<template>
  <div id="app">
    <headerMain/>
    <formCheck/>
  </div>
</template>

<script>
import headerMain from './components/headerMain.vue'
import formCheck from './components/formCheck.vue'

export default {
  name: 'App',
  components: {
    headerMain,
    formCheck
  }
}
</script>

<style>
#app {
  overflow: hidden;
  padding-bottom: 2rem;
}

[role="alert"] {
  word-wrap: break-word;
}
</style>
