import VueRouter from 'vue-router'

import mainPage from './'

export default new VueRouter({
    routes: [
        {
            path: '/',
            component: mainPage
        }
    ]
})